<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" href="/register-pateint">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          HEALTHPRO
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Hey, let us get you on board!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- firstname -->
              <b-form-group label="First Name" label-for="register-firstname">
                <validation-provider #default="{ errors }" name="First name" rules="required">
                  <b-form-input id="register-firstname" v-model="firstname" name="register-firstname"
                    :state="errors.length > 0 ? false : null" placeholder="john" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- firstname -->
              <b-form-group label="Last Name" label-for="register-lastname">
                <validation-provider #default="{ errors }" name="Last name" rules="required">
                  <b-form-input id="register-lastname" v-model="lastname" name="register-lastname"
                    :state="errors.length > 0 ? false : null" placeholder="Doe" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="register-email" v-model="userEmail" name="register-email"
                    :state="errors.length > 0 ? false : null" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="register-password" v-model="password" class="form-control-merge"
                      :type="passwordFieldType" :state="errors.length > 0 ? false : null" name="register-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox id="register-privacy-policy" v-model="status" name="checkbox-1">
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button variant="primary" block type="submit" @click.prevent="validationForm">
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <p class="text-center mt-4">
            <span>Take me back </span>
            <b-link href="/">
              <span>&nbsp;home</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      firstname: '',
      lastname: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      payload: {
        doc: { "docstatus": 0, "doctype": "User", "name": "new-user-ttjpyvexih", "__islocal": 1, "__unsaved": 1, "owner": "Administrator", "enabled": 1, "language": "en", "send_welcome_email": 0, "unsubscribed": 0, "desk_theme": "Light", "mute_sounds": 0, "logout_all_sessions": 1, "document_follow_notify": 0, "document_follow_frequency": "Daily", "follow_created_documents": 0, "follow_commented_documents": 0, "follow_liked_documents": 0, "follow_assigned_documents": 0, "follow_shared_documents": 0, "thread_notify": 1, "send_me_a_copy": 0, "allowed_in_mentions": 1, "simultaneous_sessions": 1, "user_type": "System User", "bypass_restrict_ip_check_if_2fa_enabled": 0, "onboarding_status": "{}", "__run_link_triggers": 1, "email": "testinguser@gmail.com", "first_name": "Testing", "last_name": "User", "role_profile_name": "" }
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.api( 'healthpro.api.auth.register.register_user',
          {
            email: this.userEmail,
            first_name: this.firstname,
            last_name: this.lastname,
            password: this.password,
          })
            .then(response => {
              this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {
              console.log(error.response.data.exception)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Registration Error',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                  text: 'Invalid registration, ensure email is valid and ensure password is 8 characters long and includes symbols, numbers and capital letters',
                },
              })
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
